import { PAGE_TYPE, PATH_PARAM_DASHBOARD, PATH_PARAM_TOKEN } from '../PATH_PARAM';
import FrontendRoutesBase from './FrontendRoutesBase';
import IFrontendRoutes from './IFrontendRoutes';

export default class ContractPORoutes extends FrontendRoutesBase implements IFrontendRoutes {
  root = this.getDBPath(PATH_PARAM_DASHBOARD.contractPO.root);

  getInstanceBc: IFrontendRoutes.BreadcrumbsGetter<typeof PATH_PARAM_TOKEN.contract> = (_params, _permissions, translate) => {
    return [{ name: translate('menus.main.contracts'), href: this.getListPath() }];
  };
  getInstanceChildBc: IFrontendRoutes.BreadcrumbsGetterWithNames<typeof PATH_PARAM_TOKEN.contract, 'contractName'> = (
    params,
    permissions,
    translate,
    names
  ) => {
    const breadcrumbs = this.getInstanceBc(params, permissions, translate);
    breadcrumbs.push({ name: names.contractName, href: this.getInfoPath(params) });
    return breadcrumbs;
  };

  getListBc = this.getEmptyBreadcrumbs;
  getListPath = () => this.getDBPath(PATH_PARAM_DASHBOARD.contractPO.root, PAGE_TYPE.list);

  getInfoBc = this.getInstanceBc;
  getInfoPath: IFrontendRoutes.PathGetter<typeof PATH_PARAM_TOKEN.contract> = (params) =>
    this.getDBPath(PATH_PARAM_DASHBOARD.contractPO.root, params.contractId, PAGE_TYPE.info);

  getAddBc = this.getInstanceBc;
  getAddPath = () => this.getDBPath(PATH_PARAM_DASHBOARD.contractPO.root, PAGE_TYPE.new, PAGE_TYPE.add);

  getEditPath: IFrontendRoutes.PathGetter<typeof PATH_PARAM_TOKEN.contract> = (params) =>
    this.getDBPath(PATH_PARAM_DASHBOARD.contractPO.root, params.contractId, PAGE_TYPE.edit);
  getEditBc = this.getInstanceChildBc;

  getPriceListPath: IFrontendRoutes.PathGetter<typeof PATH_PARAM_TOKEN.contract> = (params) =>
    this.getDBPath(PATH_PARAM_DASHBOARD.contractPO.root, params.contractId, PATH_PARAM_DASHBOARD.contractPO.prices, PAGE_TYPE.list);
  getPriceListBc = this.getInstanceBc;

  getLegalEntitiesPath: IFrontendRoutes.PathGetter<typeof PATH_PARAM_TOKEN.contract> = (params) =>
    this.getDBPath(PATH_PARAM_DASHBOARD.contractPO.root, params.contractId, PATH_PARAM_DASHBOARD.contractPO.legalEntity);
  getLegalEntitiesBc = this.getInstanceBc;

  getLegalEntitiesEnterprisePath: IFrontendRoutes.PathGetter<typeof PATH_PARAM_TOKEN.contract> = (params) =>
    this.getDBPath(PATH_PARAM_DASHBOARD.contractPO.root, params.contractId, PATH_PARAM_DASHBOARD.contractPO.legalEntityEnterprise);
  getLegalEntitiesEnterpriseBc = this.getInstanceBc;

  getReviewsPath: IFrontendRoutes.PathGetter<typeof PATH_PARAM_TOKEN.contract> = (params) =>
    this.getDBPath(PATH_PARAM_DASHBOARD.contractPO.root, params.contractId, PATH_PARAM_DASHBOARD.contractPO.reviews);
  getReviewsBc = this.getInstanceBc;

  getSettingsEditPath: IFrontendRoutes.PathGetter<typeof PATH_PARAM_TOKEN.contract> = (params) =>
    this.getDBPath(PATH_PARAM_DASHBOARD.contractPO.root, params.contractId, PATH_PARAM_DASHBOARD.contractPO.prices, PAGE_TYPE.edit);
  getSettingsEditBc: IFrontendRoutes.BreadcrumbsGetterWithNames<typeof PATH_PARAM_TOKEN.contract, 'contractName'> = (
    params,
    permissions,
    translate,
    names
  ) => {
    const breadcrumbs = this.getInstanceBc(params, permissions, translate);
    breadcrumbs.push({ name: names.contractName, href: this.getPriceListPath(params) });
    return breadcrumbs;
  };
}
