import { CreateServiceOrderedRequestDTO, ServiceOrderedResponseDTO } from 'typings/dto/serviceOrdered';
import Mapper from './Mapper';

export default class ServiceOrderedMapper extends Mapper<ServiceOrdered, ServiceOrderedResponseDTO, CreateServiceOrderedRequestDTO> {
  constructor() {
    super(
      {
        custom: 'isCustom',
        description: 'description',
        includedText: 'included',
        name: 'name',
        notIncludedText: 'notIncluded',
        paid: 'isPaid',
        paymentType: 'paymentType',
        quantity: 'quantity',
        technicalName: 'technicalName',
        catalogId: 'catalogId',
        price: 'price',
        workEstimation: 'workEstimation',
        providerReward: null,
        linkedEntities: 'linkedEntities',
        platformOperatorReward: null,
        promotion: 'promotion',
        productPrice: 'productPrice',
      },
      {
        isCustom: 'custom',
        description: 'description',
        included: 'includedText',
        name: 'name',
        notIncluded: 'notIncludedText',
        isPaid: 'paid',
        paymentType: 'paymentType',
        quantity: 'quantity',
        technicalName: 'technicalName',
        catalogId: 'catalogId',
        price: 'price',
        isFree: null,
        workEstimation: 'workEstimation',
        linkedEntities: 'linkedEntities',
        promotion: 'promotion',
        productPrice: 'productPrice',
      }
    );
  }

  public responseDTOToModel(dto: ServiceOrderedResponseDTO): ServiceOrdered {
    let discount = 0;
    if (dto.price.discount) {
      if (dto.price.discount.type === 'AMOUNT') discount = dto.price.discount.value;
      else discount = ((dto.price.value * dto.quantity) / 100) * dto.price.discount.value;
    }
    let totalClientValue = dto.price.value * dto.quantity - discount;
    if (dto.price.discount?.type === 'PERCENT') {
      totalClientValue = Math.round(totalClientValue);
    }

    return {
      ...this.responseDTOToModelViaDiffMap(dto),
      isFree: totalClientValue <= 0,
      price: {
        currency: dto.price.currency,
        discount: dto.price.discount || { type: 'PERCENT', value: 0 },
        initialValue: dto.price.value,
        totalClientValue,
        providerValue: dto.providerReward.value || 0,
        providerSaleBonus: dto.price.providerSaleBonus,
        totalProviderValue: (dto.providerReward.value || 0) * dto.quantity,
        totalProviderSaleBonus: (dto.providerReward.saleBonusValue || 0) * dto.quantity,
        platformOperatorReward: (dto.platformOperatorReward.value || 0) * dto.quantity,
        platformOperatorBonus: (dto.platformOperatorReward.saleBonusValue || 0) * dto.quantity,
        clientPrice: dto.price.catalogPrice,
        providerPrice: dto.providerReward.catalogPrice || null,
      },
      promotion: dto.promotion,
      workEstimation:
        typeof dto.workEstimation?.durationMinutes === 'number' ? { durationMinutes: dto.workEstimation.durationMinutes! } : undefined,
    };
  }
}
